import React from 'react'
import WebFont from 'webfontloader';
import { CookiesProvider } from 'react-cookie';
import './src/StyleGuide/styles/global.scss';
import './src/StyleGuide/styles/global.typography.scss';
import './src/StyleGuide/styles/global.qld.scss';
import '@cdm/quickenloans-library/lib/index.css';
import useTags from './src/utilities/useTags';

const typekitId = 'cie5zve';

WebFont.load({
    typekit: {
        id: typekitId
    }
})

export const wrapRootElement = ({ element }) => (
    <CookiesProvider>{element}</CookiesProvider>
);

const AdobePageViewTrigger = ({props, element}) =>
{
    const pathname = props?.pageResources?.page?.path || 'unknown';
    const {sendPageView} = useTags( pathname )
    sendPageView();
    return element;
}

export const wrapPageElement = ( args ) =>
    (
        <AdobePageViewTrigger {...args} />
    )

export const onRouteUpdate = ({location, prevLocation}) => 
{
    if (window &&
        (location.pathname === '/calculators/home-affordability-calculator' || location.pathname === '/calculators/home-affordability-calculator/') &&
        location && prevLocation && location.pathname !== prevLocation.pathname) 
    {

        window.location.reload();
    }
}